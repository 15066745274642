import firstBenefit from "@images/benefit1.svg"
import thirdBenefit from "@images/benefit3.svg"
import hat from "@images/hat.svg"
import tax from "@images/tax.svg"
import golocker from "@images/goLocker-logo.png"
import lulu from "@images/lulu.png"
import { BADGES } from "../../constants"

export const HEADER_BACKGROUND_IMAGE =
  "https://files.impressit.io/telecom_8af100eab1.png"
export const HEADER_DESCRIPTION =
  "The core benefit of working with Impressit is gaining access to our expertise in developing custom telecom software solutions that improve operational efficiency, automate processes, and enhance customer experience."
export const HEADER_TITLE = "Telecommunication software development services"
export const HEADER_BUTTON_TEXT = "Let's chat"
export const MAIN_OFFERS_PRESENTATION_SECTION_TITLE =
  "Telecom software development services"
export const MAIN_OFFERS_PRESENTATION_SECTION_DESCRIPTION =
  "We provide the following telecom software development services:"
export const MAIN_OFFERS_PRESENTATION_WITH_IMAGE_TITLE =
  "Our expertise in software development for telecom"

export const FAQ_SECTION_ITEMS = [
  {
    title: "Why should I choose your telecom software company?",
    text: "The telecom industry is expanding rapidly, and the demand for data transmission is growing with it. Our developers can help you stand out in this highly competitive industry. Failure to keep up with the competition can lead to revenue decline, which some communication service providers are facing. At Impressit, we understand the key features required in software development for telecom. Our skilled team can develop robust systems such as Operations Support Systems or Business Support Systems. We can create any feature you require, including monitoring solutions, charging and billing systems, customer self-service portals, and more. Furthermore, all of our telecommunication software projects are customized to meet the unique needs of our clients, ensuring that you can achieve your business objectives. Custom software development and process automation are essential for modern telecommunication infrastructure to function smoothly. Impressit continuously implements cutting-edge technology to ensure that your operation support system consistently exceeds industry standards.",
  },
  {
    title: "Can you integrate my current telecom platforms?",
    text: "As a leading telecom software development, Impressit offers custom-tailored solutions that seamlessly integrate with third-party telecommunications APIs, cloud communication platforms, expense management systems, and more. Our expert team will work closely with you to understand your unique needs and goals and integrate the features you require to succeed in your business.",
  },
  {
    title:
      "What is the estimated time required for building and implementing software for telecom?",
    text: "The duration of telecom software development varies depending on factors like project complexity and desired features. Once we understand your requirements during the consultation phase, we can provide an estimated timeframe.",
  },
  {
    title: "What kinds of network applications are you able to develop?",
    text: "Our team of expert developers specializes in creating software for telecom. We can design communication portals and network applications tailored to your organization's needs. Whether you require a call center optimization app or a team messaging solution, we can provide a customized solution. Impressit is one of the leading telecom software companies in the industry, delivering high-quality mobile and web apps to our clients.",
  },
  {
    title: "What is a telecommunications system?",
    text: "Telecommunications is a system that enables long-distance communication through a network of nodes and links. Examples of such systems are the internet and telephone network. Telecom software developers design and construct the diverse range of technologies required for such systems. If you're unsure about what telecommunications are, our team can provide you with a clear understanding.",
  },
]

export const FEEDBACK_SECTION_ITEMS = [
  {
    member: "Ross Ducat",
    position: "Founder & CEO, Lulu Light",
    logoSrc: lulu,
    feedbackText: "Their speed and knowledge were impressive",
  },
  {
    member: "Sherin Davis",
    position: "Chief Product Officer, GoLocker",
    logoSrc: golocker,
    feedbackText: "Overall, they are great partners",
  },
]

export const MAIN_OFFERS_PRESENTATION_WITH_IMAGE_ITEMS = [
  {
    title: "Operations Support Systems (OSS)",
    imgSrc: "https://files.impressit.io/impressit_team_min_5ca76fa91d.jpg",
    text: "Our company specializes in developing suites of back-office applications aimed at supporting telecom services and ensuring the prompt resolution of network disruptions. Our skilled developers craft personalized Operation Support Systems (OSS) that enhance the efficiency of multiple operations, including network resources inventory management, fault management, fraud protection solutions, performance management, trouble ticketing, customer billing management, data reports, and dashboards, and security authentication. These applications maintain the operability of physical equipment and logical assets, ensuring seamless operations of your telecom services.",
  },
  {
    title: "Business Support Systems (BSS)",
    imgSrc: "https://files.impressit.io/impressit_team2_min_b54ef68ed0.jpg",
    text: "Our company builds highly-functional software that streamlines multiple business departments, automates labor-intensive and data-heavy processes, and promotes enterprise-wide performance visibility. Our team develops customized Business Support Systems (BSS) that streamline your product management, order processing, revenue generation, and customer relations. Our solutions include tailor-made platforms, applications, and tools such as CRM systems, ERP software solutions, billing systems, customer self-service portals, personnel management corporate portals, predictive analytics solutions, report generation tools, and sales and lead generation automation systems. With our software, you can optimize business operations and enhance customer satisfaction, ultimately leading to increased revenue and growth.",
  },
]

export const MAIN_OFFERS_PRESENTATION_SECTION_ITEMS = [
  {
    title: "Dedicated developers for telecom companies",
    text: "By hiring our dedicated software development teams you get access to industry experts with in-depth knowledge of the latest technologies, cost savings by avoiding the need to hire and train in-house staff, and flexibility to quickly adapt to market needs.",
  },
  {
    title: "Custom telecom software development",
    text: "Custom software development for telecom provides tailored solutions that streamline processes, boost efficiency, and adapt to changing market trends. It also creates differentiation, strategic advantage, and better customer experiences. In short, software development for telecom drives long-term success by achieving business goals.",
  },
]

export const MAIN_OFFERS_PRESENTATION_SECTION_UNDERSECTION_TEXT =
  "Choose your telecom software development service"

export const MAIN_OFFERS_PRESENTATION_SECTION_BUTTON_TEXT = "Let's talk"

export const INLINE_MAIN_OFFERS_PRESENTATION_SECTION_TITLE =
  "We can help you to:"

export const INLINE_MAIN_OFFERS_PRESENTATION_SECTION_ITEMS = [
  {
    title: "Deliver Cloud-Based Solutions",
    text: "Take your telecom services to new heights with cloud-native architectures and a microservice-based approach. These solutions offer greater flexibility, scalability, and cost-efficiency while enhancing the speed and agility of your services. Let us help you implement custom cloud-based solutions that meet your specific business needs and exceed customer expectations.",
  },
  {
    title: "Monitor performance issues",
    text: "Streamline your management workflows and optimize performance with OSS/BSS software, network inventory tools, customer and revenue management software, order orchestration, and other automation solutions. Our custom software development services can help you implement tailored solutions that meet your unique business needs and improve the efficiency and profitability of your operations.",
  },
  {
    title: "Enhance customer experience",
    text: "Improve your customers' experiences managing their services and financial activities with a device-independent point of contact. We can help you develop custom solutions that enable customers to easily access and manage their accounts from any device, enhancing their satisfaction and loyalty.",
  },
  {
    title: "Implement AI solutions",
    text: "From predictive analytics for network optimization to proactive customer support, our AI expertise enhances operational efficiency and elevates customer experiences. Embrace the future of telecom with cutting-edge AI applications tailored to your unique needs.",
  },
]

export const CASE_STUDIES = [
  {
    id: "Case-studies_3",
    logo: { url: "https://files.impressit.io/gnetwork_logo_f8afe9daf5.svg" },
    metaUrl: "gnetwork",
    title:
      "Business automation system for the fastest growing networking company in London",
    wideImage: { url: "https://files.impressit.io/3gnetwork_4774e7179b.svg" },
  },
  {
    id: "Case-studies_34",
    logo: { url: "https://files.impressit.io/freedom_fibre_logo_9b23d6eb5f.svg" },
    metaUrl: "freedom-fibre",
    title:
      "Automation solutions development for a fast-growing UK broadband company",
    wideImage: { url: "https://files.impressit.io/freedom_fibre_wide2_8ad15c6658.png" },
  },
]

export const WHY_SCHOOSE_SECTION_TITLE = "Why choose Impressit?"

export const WHY_CHOOSE_ITEMS = [
  {
    title: "Full commitment",
    iconFromMarkupPath: firstBenefit,
    description:
      "At Impressit, we are equally dedicated to the success of your business and committed to supporting you throughout the entire development process.",
  },
  {
    title: "Time & costs savings",
    iconFromMarkupPath: tax,
    description:
      "Partnering with Impressit for quality development is crucial in avoiding future issues. Our experienced developers create top-notch products with comprehensive maintenance support for the lifetime of your software. Don't settle for less; prioritize security, functionality, and up-to-date technology with Impressit.",
  },
  {
    title: "Reduced Risks",
    iconFromMarkupPath: thirdBenefit,
    description:
      "While it may be tempting to hire freelance developers who offer lower hourly rates, it could end up costing you more in terms of security concerns, incomplete documentation, and subpar code.",
  },
  {
    title: "Experience",
    iconFromMarkupPath: hat,
    description:
      "It is a top priority for us to ensure that you are updated on all aspects of your software. We are delighted to provide our experience and insight to support you as you go through the production journey.",
  },
]

export const CUSTOM_BADGES = [
  { index: 0, ...BADGES.clutchTelecommunication },
  { index: 1, ...BADGES.clutchFastestGrowth },
  { index: 2, ...BADGES.clutchGlobalSpring },
]

export const SECOND_CATCH_UP_SECTION_TITLE = "Get in touch"

export const SECOND_CATCH_UP_SECTION_DESCRIPTION =
  "Leave us a note and we will contact you within 24 hours"
