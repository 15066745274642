import React from "react"
import loadable from "@loadable/component"
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "@links"
import useDelayedRender from "@hooks/useDelayedRender"
import {
  CASE_STUDIES,
  WHY_CHOOSE_ITEMS,
  HEADER_TITLE,
  HEADER_DESCRIPTION,
  HEADER_BUTTON_TEXT,
  MAIN_OFFERS_PRESENTATION_SECTION_TITLE,
  MAIN_OFFERS_PRESENTATION_SECTION_DESCRIPTION,
  MAIN_OFFERS_PRESENTATION_SECTION_ITEMS,
  MAIN_OFFERS_PRESENTATION_WITH_IMAGE_TITLE,
  MAIN_OFFERS_PRESENTATION_WITH_IMAGE_ITEMS,
  FEEDBACK_SECTION_ITEMS,
  FAQ_SECTION_ITEMS,
  HEADER_BACKGROUND_IMAGE,
  MAIN_OFFERS_PRESENTATION_SECTION_UNDERSECTION_TEXT,
  MAIN_OFFERS_PRESENTATION_SECTION_BUTTON_TEXT,
  INLINE_MAIN_OFFERS_PRESENTATION_SECTION_TITLE,
  INLINE_MAIN_OFFERS_PRESENTATION_SECTION_ITEMS,
  WHY_SCHOOSE_SECTION_TITLE,
  SECOND_CATCH_UP_SECTION_TITLE,
  SECOND_CATCH_UP_SECTION_DESCRIPTION,
  CUSTOM_BADGES,
} from "./constants"

const PageHeader = loadable(() =>
  import("@components/Landing/components/PageHeader/PageHeader")
)

const CenteredContainer = loadable(() => import("@common/CenteredContainer"))

const MainOffersPresentationSection = loadable(() =>
  import(
    "@components/Landing/components/MainOffersPresentationSection/MainOffersPresentationSection"
  )
)

const MainOffersPresentationSectionWithImage = loadable(() =>
  import(
    "@components/Landing/components/MainOffersPresentationSectionWithImages/MainOffersPresentationSectionWithImages"
  )
)

const CaseStudySection = loadable(() =>
  import("@components/Landing/components/CasesStudySection/CasesStudySection")
)

const BenefitsFlippableListSection = loadable(() =>
  import("@common/CommonSections/BenefitsFlippableListSection")
)

const FeedbackSection = loadable(() =>
  import(
    "@common/SlideShow/ContentWithFeedback/FeedbacksSection/FeedbacksSection"
  )
)

const ProudSection = loadable(() =>
  import(
    "@components/Landing/components/ProudSectionWrapper/ProudSectionWrapper"
  )
)

const FAQSection = loadable(() =>
  import("@components/Landing/components/FAQSection/FAQSection")
)

const CrawlableSecondCatchUpSection = loadable(() =>
  import("@common/CommonSections/CrawlableSecondCatchUpSection")
)

const LinkButton = loadable(() => import("@common/LinkButton"))

const Telecommunication = () => {
  const { isDelayed } = useDelayedRender(100)
  return (
    <>
      <PageHeader
        headerButtonNavigation={GLOBAL_APPLICATION_INTERNAL_LINKS.CONTACT_US}
        headerDescription={HEADER_DESCRIPTION}
        headerTitle={HEADER_TITLE}
        headerButtonText={HEADER_BUTTON_TEXT}
        headerMainImage={HEADER_BACKGROUND_IMAGE}
      />
      {isDelayed && (
        <CenteredContainer>
          <MainOffersPresentationSection
            isSquareSection="true"
            title={MAIN_OFFERS_PRESENTATION_SECTION_TITLE}
            description={MAIN_OFFERS_PRESENTATION_SECTION_DESCRIPTION}
            offers={MAIN_OFFERS_PRESENTATION_SECTION_ITEMS}
            textUnderSection={
              MAIN_OFFERS_PRESENTATION_SECTION_UNDERSECTION_TEXT
            }
            buttonText={MAIN_OFFERS_PRESENTATION_SECTION_BUTTON_TEXT}
            buttonNavigation={GLOBAL_APPLICATION_INTERNAL_LINKS.CONTACT_US}
          />
          <MainOffersPresentationSectionWithImage
            title={MAIN_OFFERS_PRESENTATION_WITH_IMAGE_TITLE}
            offers={MAIN_OFFERS_PRESENTATION_WITH_IMAGE_ITEMS}
          />
          <MainOffersPresentationSection
            title={INLINE_MAIN_OFFERS_PRESENTATION_SECTION_TITLE}
            description=""
            offers={INLINE_MAIN_OFFERS_PRESENTATION_SECTION_ITEMS}
          />
          <CaseStudySection
            nodes={CASE_STUDIES}
            cases="gnetwork, freedom-fibre"
          />
          <BenefitsFlippableListSection
            heading={WHY_SCHOOSE_SECTION_TITLE}
            configuration={WHY_CHOOSE_ITEMS}
          />
          <FeedbackSection
            feedbacks={FEEDBACK_SECTION_ITEMS}
            title="Our Clients say"
          />
          <ProudSection customBadges={CUSTOM_BADGES} />
          <FAQSection items={FAQ_SECTION_ITEMS} />
          <CrawlableSecondCatchUpSection
            isAnimated
            title={SECOND_CATCH_UP_SECTION_TITLE}
            description={SECOND_CATCH_UP_SECTION_DESCRIPTION}
          >
            <LinkButton to={GLOBAL_APPLICATION_INTERNAL_LINKS.CONTACT_US}>
              Contact Us
            </LinkButton>
          </CrawlableSecondCatchUpSection>
        </CenteredContainer>
      )}
    </>
  )
}

export default Telecommunication
